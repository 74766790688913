import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useBusinessContext } from "../../storage/context/BusinessContext";
import { ResponsiveContainer } from "../../components/DefaultResponsiveContainer";
import { AddressElement, Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useQuery } from "@tanstack/react-query";
import { getAPIStripeKey, getStripePaymentIntentSecret } from "../../api/subscription-api";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Container, Divider, Form, Message, Segment } from "semantic-ui-react";
import { useGeneralContext } from "../../storage/context/GeneralContext";

const stripePromise = loadStripe("pk_live_51OOy2iIH7VsiWHUvkoBpsGIvOdS6uG58IJ7JOfDxedXVMrmvPbllPgfoT4BbMkECfyNS00tWRpk7SmTNpLZkRx1A00DSuKeV89");

interface BillingDetails {
    businessId: string;
    clientSecret: string;
    amount: number;
    currency: string
}

function StripeCheckoutForm({ businessId, clientSecret, amount, currency }: BillingDetails) {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const handleSubmit = async (event: React.FormEvent) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setLoading(() => true);

        const { error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: `https://business.chaguoo.com/business/${businessId}`,
            },
        });

        setLoading(() => false);
        
        if (error) {
            console.error("Payment failed", error);
        }
    };

    const formatter = new Intl.NumberFormat('en-US', { style: "currency", currency: currency });

    return (
        <Form
            onSubmit={handleSubmit}
            loading={loading}
            style={{
                marginBottom: '2em',
            }}
        >
            <Container text
                style={{
                    marginTop: '2em',
                    marginBottom: '10em',
                    borderWidth: '1px',
                    borderRadius: '5px',
                    borderColor: 'rgba(34,36,38,.15)',
                }}
            >
                <Container>
                    <p>
                        You are about to pay :<br />
                        <strong
                            style={{
                                fontSize: '1.5em'
                            }}
                        >
                            {formatter.format(amount)}
                        </strong>
                    </p>
                </Container>
                <Container>
                    <p>
                        Please enter your billing details below:
                    </p>
                    <AddressElement options={{ mode: 'billing' }} />
                </Container>
                <Divider />
                <Container>
                    <p>
                        Please select your payment method below:
                    </p>
                    <PaymentElement
                        options={{
                            layout: {
                                type: 'accordion',
                            },
                            business: {
                                name: "Chaguoo.com"
                            }
                        }}
                    />
                </Container>
                <Button
                    primary
                    type="submit"
                    content="Pay"
                    style={{
                        marginTop: '1em'
                    }}
                />
            </Container>
        </Form>
    );
}

export function StripeInvoicePaymentPage() {
    const { businessid } = useParams();
    const { invoiceid } = useParams();
    const { setCurrentBusiness } = useBusinessContext();
    const { currentUser } = useGeneralContext();

    useEffect(() => {
        if (businessid) {
            setCurrentBusiness(businessid)
        }
    }, [businessid, setCurrentBusiness]);

    const {
        data: stripeKey,
        isLoading,
    } = useQuery({
        queryKey: ["stripe-key"],
        queryFn: async () => {
            return await getAPIStripeKey();
        },
    });

    const {
        data: clientSecret,
        isLoading: clientSecretLoading,
    } = useQuery({
        queryKey: ["stripe-client-intent", invoiceid],
        queryFn: async () => {
            if (!currentUser) return null;
            if (!invoiceid) return null;
            const idToken = await currentUser.getIdToken();
            return await getStripePaymentIntentSecret(invoiceid, idToken);
        },
        enabled: !!stripeKey && !!currentUser
    });

    return (
        <ResponsiveContainer>
            {isLoading || clientSecretLoading ? <p>Loading...</p> : null}
            {clientSecret && businessid && !["succeeded", "completed"].includes(clientSecret.status) ?
                (
                    <Elements
                        stripe={stripePromise}
                        options={{
                            clientSecret: clientSecret.client_secret,
                        }}
                    >
                        <StripeCheckoutForm
                            businessId={businessid}
                            amount={clientSecret.amount}
                            currency={clientSecret.currency}
                            clientSecret={clientSecret.client_secret}
                        />
                    </Elements>
                ) : null
            }
            {clientSecret && ["succeeded", "completed"].includes(clientSecret.status) ?
                <Segment basic
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                        width: "min(100%, 500px)",
                        margin: "auto"
                    }}
                >
                    <Message
                        positive
                        style={{
                            width: "min(100%, 500px)",
                        }}
                    >
                        <Message.Header>Payment Succeeded</Message.Header>
                        <p>Your payment has been successfully processed.</p>
                    </Message>
                </Segment>
                : null
            }
        </ResponsiveContainer>
    );
}