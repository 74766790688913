import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useBusinessContext } from "../../storage/context/BusinessContext";
import { infiniteBusinessPostsNextBatch, infiniteBusinessPostsStart } from "../../api/firebase";
import { PostData } from "../../models/models";
import { Button, Container, Dimmer, Form, Icon, Input, InputOnChangeData, List, Loader, Message, Modal, Table, TextArea, TextAreaProps } from "semantic-ui-react";
import { PostContextStatus, usePostContext } from "../../storage/context/PostsContext";
import MediaSlider from "../../components/MediaSlider";
import { IMAGE_MAX_SIZE } from "../../common/file";
import * as Sentry from "@sentry/react";
import { ErrorPopup } from "../../components/ErrorPopup";

interface PostModificationModalProps {
    onClose: () => void;
    onOpen: () => void;
    visible: boolean;
    onError: (msg: string) => void;
}

function PostModificationModal({ onClose, onOpen, visible, onError }: PostModificationModalProps) {
    const { currentBusiness } = useBusinessContext();
    const { addFiles, clearState, filesBeingUploaded, filesUploaded,
        uploadPost, updatePost, status, text, setText, removeFiles, deletePost } = usePostContext();
    const [loading, setLoading] = useState(false);
    const [errorMesssage, setErrorMessage] = useState("");

    const handleFileSelect = useCallback((e: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        if (e.target.files) {
            const selectedFiles = Array.from(e.target.files).filter((val) => {
                if (val.size > IMAGE_MAX_SIZE) {
                    Sentry.captureMessage(`File ${val.name} surpasses the maximum size. Business ID`, "error");
                    onError(`File ${val.name} surpasses the maximum size. Business ID ${currentBusiness?.store_id}`);
                    setErrorMessage(`File ${val.name} surpasses the maximum size.`);
                    return false;
                }
                return true;
            });
            if (selectedFiles.length === 0) {
                document.getElementById("mime-selector")?.setAttribute("value", "");
            } else {
                addFiles(selectedFiles);
            }
        }
    }, [addFiles, currentBusiness, setErrorMessage, onError]);

    const onSave = () => {
        setLoading(true);
        if (status === PostContextStatus.New) {
            uploadPost()
                .then(() => { setLoading(false); clearState(); onClose() })
                .catch((err) => {
                    setErrorMessage((err as Error).message);
                    setLoading(false);
                    Sentry.captureException(err);
                    clearState();
                });
        } else if (status === PostContextStatus.Updating) {
            updatePost()
                .then(() => { setLoading(false); clearState(); onClose() })
                .catch((err) => {
                    setErrorMessage((err as Error).message);
                    Sentry.captureException(err);
                    setLoading(false);
                });
        }
    }
    const onDelete = () => {
        setLoading(true);
        deletePost()
            .then(() => { setLoading(false); clearState(); onClose() })
            .catch((err) => {
                setErrorMessage((err as Error).message);
                Sentry.captureException(err);
                setLoading(false);
            })
    }

    const onTextAreaChange = useCallback(async (e: ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
        setErrorMessage("");
        setText(e.target.value);
    }, [setText, setErrorMessage]);

    return (
        <Modal
            onClose={onClose}
            onOpen={onOpen}
            open={visible}
        >
            <Modal.Header>
                {status === PostContextStatus.New ? "Adding New Post" : "Modifying Post"}
                <Loader active inline />
            </Modal.Header>
            <Modal.Content image>
                <Dimmer active={loading}>
                    <Loader content='Loading' />
                </Dimmer>
                <Container>
                    {status === PostContextStatus.New ?
                        <MediaSlider localFiles={filesBeingUploaded} />
                        :
                        <MediaSlider remoteFiles={filesUploaded} />
                    }
                </Container>
                <Container>
                    <Modal.Description style={{ marginLeft: 10 }}>
                        <Form>
                            <Form.Field>
                                <label>Text</label>
                                <TextArea placeholder='Write your text' value={text} onChange={onTextAreaChange} />
                            </Form.Field>
                            {status === PostContextStatus.New && <Form.Field>
                                <Input id="mime-selector" type="file" accept="image/*,video/*" onChange={handleFileSelect} />
                                Maximum video or image size 128 MiB.
                            </Form.Field>}
                        </Form>
                        {status === PostContextStatus.New && <List divided verticalAlign="middle">
                            {filesBeingUploaded.map((file, index) => (
                                <List.Item key={index}>
                                    <List.Content floated="right">
                                        <Button content="Delete" color="red" onClick={() => removeFiles([file])} />
                                    </List.Content>
                                    {file.name}
                                </List.Item>
                            ))}
                        </List>}
                        {errorMesssage &&
                            <Message negative>
                                <Message.Header>There was some errors with your submission</Message.Header>
                                <p>{errorMesssage}</p>
                            </Message>}
                    </Modal.Description>
                </Container>
            </Modal.Content>
            <Modal.Actions>
                <Button content="Close" color='black' onClick={onClose} />
                {status === PostContextStatus.Updating &&
                    <Button
                        content="Delete"
                        color="red"
                        icon="trash"
                        labelPosition="left"
                        loading={loading}
                        onClick={onDelete}
                    />
                }
                <Button
                    content={status === PostContextStatus.New ? "Upload" : "Update"}
                    labelPosition='left'
                    icon='checkmark'
                    onClick={onSave}
                    positive
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    )
}

export default function PostsSubPage() {
    const mounted = useRef(false);
    const { currentBusiness } = useBusinessContext()
    const { setStatus, setPost } = usePostContext()
    const [data, setData] = useState<PostData[]>([]);
    const [lastObject, setLastObject] = useState<any>(null);
    const [postModifModalOpen, setPostModifModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMesssage, setErrorMessage] = useState("");

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        }
    }, []);

    useEffect(() => {
        const abortController = new AbortController();
        fetchDataInitialData(abortController.signal);
        return () => {
            abortController.abort();
        }
    }, []); // Fire only once when the component is mounted

    const fetchDataInitialData = useCallback(async (signal: AbortSignal | null = null) => {
        if (!mounted.current || signal?.aborted) return;
        setLoading(() => true);
        try {
            if (currentBusiness) {
                if (!mounted.current) return;
                setData(() => []);
                const [newIncomingData, startFromObject] = await infiniteBusinessPostsStart(currentBusiness.store_id);
                if (newIncomingData.length > 0) {
                    if (!mounted.current || signal?.aborted) return;
                    setData((prevData) => [...prevData, ...newIncomingData]);
                    setLastObject(startFromObject);
                }
            }
        } catch (error) {
            setErrorMessage((error as Error).message);
            Sentry.captureException(error);
        } finally {
            if (!mounted.current || signal?.aborted) return;
            setLoading(() => false);
        }
    }, [currentBusiness]);

    const fetchDataNextData = useCallback(async (signal: AbortSignal | null = null) => {
        if (!mounted.current || signal?.aborted) return;
        setLoading(true);
        try {
            if (currentBusiness) {
                const [newIncomingData, startFromObject] = await infiniteBusinessPostsNextBatch(currentBusiness.store_id, lastObject);
                if (newIncomingData.length > 0) {
                    if (!mounted.current || signal?.aborted) return;
                    setData((prevData) => [...prevData, ...newIncomingData]);
                    setLastObject(startFromObject);
                }
            }
            if (!mounted.current || signal?.aborted) return;
        } catch (error) {
            setErrorMessage((error as Error).message);
            Sentry.captureException(error);
        } finally {
            setLoading(false);
        }
    }, [currentBusiness, lastObject]);

    const onAddNewPost = () => {
        setStatus(PostContextStatus.New);
        setPostModifModalOpen(true);
    }

    const onUpdatingPost = (post: PostData) => {
        setStatus(PostContextStatus.Updating);
        setPost(post);
        setPostModifModalOpen(true);
    }

    return (
        <Container fluid>
            {(errorMesssage.length > 0) ? <ErrorPopup message={errorMesssage} /> : null}
            {<PostModificationModal
                onError={(error: string) => setErrorMessage(error)}
                visible={postModifModalOpen}
                onClose={() => setPostModifModalOpen(false)}
                onOpen={() => setPostModifModalOpen(true)}
            />}
            <Container>
                <Container style={{ marginTop: 10, marginBottom: 10 }}>
                    <h2>Posts</h2>
                    <p>Below is a table with all the business posts.</p>
                </Container>
                <Button
                    icon
                    labelPosition='left'
                    primary
                    size='small'
                    active={!loading}
                    onClick={onAddNewPost}
                >
                    <Icon name='plus' /> Post
                </Button>
                <Table celled padded>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Post Id</Table.HeaderCell>
                            <Table.HeaderCell>Media Content</Table.HeaderCell>
                            <Table.HeaderCell>Text Content</Table.HeaderCell>
                            <Table.HeaderCell>Posted At</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data.map((post) =>
                            <Table.Row
                                key={post.id}
                                onClick={() => onUpdatingPost(post)}
                            >
                                <Table.Cell>{post.id}</Table.Cell>
                                <Table.Cell>{post.content.media?.length || 0}</Table.Cell>
                                <Table.Cell>{post.content.text}</Table.Cell>
                                <Table.Cell>{post.posted.toDate().toUTCString()}</Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                    <Table.Footer fullWidth>
                        <Table.Row>
                            <Table.HeaderCell colSpan='4'>
                                <Button
                                    floated='right'
                                    icon
                                    labelPosition='left'
                                    secondary
                                    size='small'
                                    active={!loading}
                                    onClick={() => fetchDataInitialData()}
                                >
                                    <Icon name='refresh' />Reload
                                </Button>
                                <Button size='small' onClick={() => fetchDataNextData()} active={!loading}>Load More</Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </Container>
        </Container>
    );
}