import { ReactNode, createContext, useCallback, useContext, useState } from "react";
import { deletePost, updatePost, uploadAPost, uploadFileToStorage } from "../../api/firebase";
import { PostContent, PostData, PostMediaContent, UpdatingPostData, UploadingPostData } from "../../models/models";
import { serverTimestamp } from "firebase/firestore";
import { useBusinessContext } from "./BusinessContext";
import uuid from "react-uuid";
import * as Sentry from "@sentry/react";

export enum PostContextStatus{
    New,
    Updating
}

interface PostContextType{
    addFiles: (files: File[]) => void;
    clearState: () => void;
    filesBeingUploaded: File[];
    filesUploaded: PostMediaContent[] | undefined;
    removeFiles: (files: File[]) => void;
    uploadPost: () => Promise<void>;
    updatePost: () => Promise<void>;
    setStatus: (status: PostContextStatus) => void;
    status: PostContextStatus;
    text: string;
    setText: (str: string) => void;
    setPost: (post: UpdatingPostData) => void;
    deletePost: () => Promise<void>;
}

const PostContext = createContext<PostContextType>({
    addFiles: (files) => {},
    clearState: () => {},
    filesBeingUploaded: [], 
    filesUploaded: undefined, 
    removeFiles: (files) => {},
    uploadPost: async () => {},
    updatePost: async () =>  {},
    setStatus: (status: PostContextStatus) => {}, 
    status: PostContextStatus.New,
    text: "", 
    setText: (str: string) => {}, 
    setPost: (post: UpdatingPostData) => {},
    deletePost: async () => {}
})

export const usePostContext = () => useContext(PostContext);

interface PostContextProviderProps{
    children?: ReactNode
}

export default function PostContextProvider({children}: PostContextProviderProps){
    const [status, setStatus]                           = useState<PostContextStatus>(PostContextStatus.New);
    const [filesBeingUploaded, setFilesBeingUploaded]   = useState<File[]>([]);
    const [filesUploaded, setFilesUploaded]             = useState<PostMediaContent[] | undefined>(undefined);
    const [text, setText]                               = useState("");
    const {currentBusiness}                             = useBusinessContext();
    const [existingPost, setExistingPost]               = useState<UpdatingPostData | undefined>(undefined);

    const clearState = () => {
        setFilesBeingUploaded([]);
        setFilesUploaded([]);
        setText("");
        setExistingPost(undefined)
    }

    const addFiles = (files: File[]) => {
        setFilesBeingUploaded((prev) => [ ...prev, ...files])
    }

    const removeFiles = (files: File[]) => {
        setFilesBeingUploaded((prev) => {
            return prev.filter((file) => !files.includes(file))
        })
    }

    const onUploadPost = useCallback(async () => {
        try {
            if (text.length === 0 && (filesBeingUploaded || []).length === 0){
                throw new Error("A post must have a text or a media file.");
            }
            if (status !== PostContextStatus.New){
                throw new Error("Status is incoherent please report to us the error with a description.")
            }
            if (!currentBusiness){
                throw new Error("Can't know what business is posting.")
            }
            const content: PostContent = {
                text: text,
            }
            const postId = uuid();
            if (filesBeingUploaded.length > 0){
                content.media = await Promise.all(filesBeingUploaded.map( async (file) => {
                    return { source: await uploadFileToStorage(file, `postsMedia/${postId}/${file.name}`), type: file.type.split("/")[0]}
                }));
            }
            const postData: UploadingPostData = {
                store_id : currentBusiness.store_id,
                id : postId,
                posted : serverTimestamp(), 
                content: content
            }
            await uploadAPost(postData);
        } catch (error) {
            Sentry.captureException(error);
        }
    }, [status, currentBusiness, text, filesBeingUploaded])

    const onUpdatePost = async () => {
        if (status !== PostContextStatus.Updating){
            throw new Error("Status is incoherent please report to us the error with a description.")
        }
        if (!currentBusiness){
            throw new Error("Can't know what business is posting.")
        }
        if (text.length === 0){
            throw new Error("The text of a post cannot be empty.");
        }
        if (!existingPost){
            throw new Error("Trying to update a non-existing post.");
        }
        const data = {...existingPost};
        data.content.text = text;
        await updatePost(existingPost);
    }

    const onSetPost = (post: PostData) => {
        setExistingPost(post);
        setText(post.content.text);
        setFilesUploaded(post.content.media);
    }

    const onDeletePost = async () => {
        if (status !== PostContextStatus.Updating){
            throw new Error("Status is incoherent please report to us the error with a description.")
        }
        if (!existingPost){
            throw new Error("Trying to delete a non-existing post.");
        }
        if (!currentBusiness){
            throw new Error("Can't know what business is posting.")
        }
        await deletePost(existingPost, currentBusiness.store_id);
    }

    const value = { 
        addFiles: addFiles, 
        clearState: clearState,
        filesBeingUploaded: filesBeingUploaded,
        filesUploaded: filesUploaded,
        removeFiles: removeFiles,
        uploadPost: onUploadPost,
        updatePost: onUpdatePost,
        setStatus: setStatus,
        status: status, 
        text: text, 
        setText: setText, 
        setPost: onSetPost, 
        deletePost: onDeletePost
    }

    return(
        <PostContext.Provider value={value}>
            {children}
        </PostContext.Provider>
    )
}