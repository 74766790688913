import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedPage from './components/ProtectedPage';
import { LoginPage } from './screens/LoginPage';
import HomePage from './screens/Home';
import BusinessPage from './screens/business/BusinessPage';
import { RegisterBusinessPage } from './screens/business/RegisterBusinessPage';
import { SignUpPage } from './screens/SignUpPage';
import { MigrationToEmail } from './screens/MigratingToMail';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StripeInvoicePaymentPage } from './screens/payments/StripeInvoicePayment';

const queryClient = new QueryClient();

function App() {
    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <Router>
                    <Routes>
                        <Route
                            path='/'
                            element={
                                <ProtectedPage
                                    page={<HomePage />}
                                />
                            }
                        />
                        <Route
                            path='/business/:businessid'
                            element={
                                <ProtectedPage
                                    page={<BusinessPage />}
                                />
                            }
                        />
                        <Route
                            path='/payment/stripe/:businessid/:invoiceid'
                            element={
                                <ProtectedPage
                                    page={<StripeInvoicePaymentPage />}
                                />
                            }
                        />
                        <Route
                            path='/register-business'
                            element={
                                <ProtectedPage
                                    page={<RegisterBusinessPage />}
                                />
                            }
                        />
                        <Route
                            path="/login"
                            element={<LoginPage />}
                        />
                        <Route
                            path='/signup'
                            element={<SignUpPage />}
                        />
                        <Route
                            path='/migration-to-email'
                            element={
                                <ProtectedPage
                                    page={<MigrationToEmail />}
                                />
                            }
                        />
                    </Routes>
                </Router>
            </QueryClientProvider>
        </div>
    );
}

export default App;
