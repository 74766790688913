import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useBusinessContext } from '../../storage/context/BusinessContext';
import { Segment, Tab } from 'semantic-ui-react';
import PostContextProvider from '../../storage/context/PostsContext';
import ProductContextProvider from '../../storage/context/ProductContext';
import BillingSubPage from './SubscriptionsPage';
import PostsSubPage from './PostsSubPage';
import ProductsSubPage from './ProductsSubPage';
import AboutSubPage from './AboutSubPage';
import AboutContextProvider from '../../storage/context/AboutContext';
import { ResponsiveContainer } from '../../components/DefaultResponsiveContainer';

export default function BusinessPage(){
    const {businessid} = useParams();
    const {setCurrentBusiness} = useBusinessContext();
    
    useEffect(() => {
        if (businessid) {
            setCurrentBusiness(businessid)
        }
    }, []);

    const panes = [
        {
            menuItem: 'Posts', 
            render: () => <Tab.Pane><PostsSubPage/></Tab.Pane> 
        },
        {
            menuItem: 'Products / Services', 
            render: () => <Tab.Pane><ProductsSubPage/></Tab.Pane> 
        },
        { 
            menuItem: 'Billing', 
            render: () => <Tab.Pane><BillingSubPage/></Tab.Pane> 
        },
        {
            menuItem: 'Business Profile', 
            render: () => <Tab.Pane><AboutSubPage/></Tab.Pane>
        }
    ]

    return (
        <PostContextProvider>
        <ProductContextProvider>
        <AboutContextProvider>
        <ResponsiveContainer>
            <Segment basic>
                <Tab
                    renderActiveOnly
                    menu={{fluid: true, tabular: true, widths: 4}}
                    panes={panes}
                />
            </Segment>
        </ResponsiveContainer>
        </AboutContextProvider>
        </ProductContextProvider>
        </PostContextProvider>
    )
}