import { useQuery } from '@tanstack/react-query';
import { Button, Message, Segment, Select, Table } from 'semantic-ui-react';
import { useBusinessContext } from '../../storage/context/BusinessContext';
import { useCallback, useState } from 'react';
import { useGeneralContext } from '../../storage/context/GeneralContext';
import { createSubscriptionForBusiness, getActivePlans, getBusinessPlans, getInvoicesForBusiness } from '../../api/subscription-api';
import { useNavigate } from 'react-router-dom';

function CreateBasicPlanSubscriptions({ business }: { business: any }) {
    const {
        data,
        isLoading,
    } = useQuery({
        queryKey: ["active-plans"],
        queryFn: async () => {
            return await getActivePlans();
        },
    });

    const [selectedPlan, setSelectedPlan] = useState<any | undefined>(undefined);
    const { currentUser } = useGeneralContext();
    const [requestLoading, setRequestLoading] = useState(false);


    const handleSubmit = useCallback(async () => {
        setRequestLoading(() => true);
        try {
            if (!currentUser) return;
            if (selectedPlan === undefined) return;
            const planObj = data?.plans?.find((plan: any) => plan.pricing_id === selectedPlan);
            if (!planObj) return;
            if (!business.store_id) return;
            const dataToSend = {
                business_id: business.store_id,
                pricing_id: planObj.pricing_id,
                status: 'active' as const,
                auto_renew: true
            };
            const idToken = await currentUser.getIdToken();
            if (!idToken) return;
            await createSubscriptionForBusiness(business.store_id, dataToSend, idToken);
        } finally {
            setRequestLoading(() => false);
        }
    }, [selectedPlan, data, currentUser, business]);

    if (isLoading) return <p>Loading...</p>;
    if (!data) return <p>No plans available</p>;

    return (
        <Segment
            basic
            style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%"
            }}
        >
            <Select
                placeholder="Select Plan"
                options={data.plans?.map((plan: any) => ({
                    key: plan.plan_id,
                    text: plan.name,
                    value: plan.pricing_id
                }))}
                onChange={(e, { value }) => setSelectedPlan(value)}
                loading={isLoading}
            />
            <Button
                primary
                content="Create Subscription"
                onClick={handleSubmit}
                loading={isLoading || requestLoading}
                active={selectedPlan !== undefined}
            />
        </Segment>
    );
}

function CurrentPlanDisplay() {
    const { currentBusiness } = useBusinessContext();
    const { currentUser } = useGeneralContext();
    const {
        data,
        isLoading,
        error
    } = useQuery({
        queryKey: ["subscription-api-business-plans", currentBusiness?.store_id],
        queryFn: async () => {
            if (!currentUser) return [];
            const idToken = await currentUser.getIdToken();
            if (!idToken) return [];
            if (!currentBusiness?.store_id) return [];
            return getBusinessPlans(currentBusiness?.store_id, idToken) || [];
        },
        retry: 1,
        enabled: !!currentBusiness?.store_id && !!currentUser
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error && !isLoading) {
        return (
            <Message negative>
                Failed to load data. Our engineers are working on it.
            </Message>
        )
    }

    if (data && data.length === 0) {
        return (
            <Segment basic fluid>
                Please Select a plan ...
                <CreateBasicPlanSubscriptions business={currentBusiness} />
            </Segment>
        )
    }

    return (
        <Segment basic fluid>
            <h2
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "20px",
                }}
            >
                Subscriptions History
            </h2>
            <Table fixed>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>ID</Table.HeaderCell>
                        <Table.HeaderCell>Start Date</Table.HeaderCell>
                        <Table.HeaderCell>End Date</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Auto Renew</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {
                        (data || []).map((plan: any, idx) => (
                            <Table.Row key={idx}>
                                <Table.Cell>{plan.pricing_id}</Table.Cell>
                                <Table.Cell>{plan.start_date}</Table.Cell>
                                <Table.Cell>{plan.end_date}</Table.Cell>
                                <Table.Cell>{plan.status}</Table.Cell>
                                <Table.Cell>{plan.auto_renew ? "Yes" : "No"}</Table.Cell>
                            </Table.Row>
                        ))
                    }
                </Table.Body>
            </Table>
        </Segment>
    );
};


function SubscriptionsHistory() {
    const {
        currentBusiness
    } = useBusinessContext();

    const {
        currentUser
    } = useGeneralContext();


    const {
        data,
        isLoading,
    } = useQuery({
        queryKey: ["subscription-api-business-invoices", currentBusiness?.store_id],
        queryFn: async () => {
            if (!currentUser) return [];
            const idToken = await currentUser.getIdToken();
            if (!idToken) return [];
            if (!currentBusiness?.store_id) return [];
            return getInvoicesForBusiness(currentBusiness?.store_id, idToken) || [];
        },
        retry: 1,
        enabled: !!currentBusiness?.store_id && !!currentUser
    });

    const navigate = useNavigate();

    const onCardPayment = useCallback((invoiceId: string) => {
        if (!currentBusiness?.store_id) return;
        if (!currentUser) return;
        if (!invoiceId) return;
        navigate(`/payment/stripe/${currentBusiness?.store_id}/${invoiceId}`);
    }, [currentUser, currentBusiness, navigate]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!data || (data?.length === 0 && !isLoading)) {
        return (
            <Segment basic fluid>
                No invoices available
            </Segment>
        )
    }

    return (
        <Segment basic fluid>
            <h2
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "20px",
                }}
            >
                Invoices
            </h2>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Issued Date</Table.HeaderCell>
                        <Table.HeaderCell>Due Date</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {data.map((item, index) => {
                        const formatter = new Intl.NumberFormat('en-US', { style: "currency", currency: item.currency });

                        return (
                            <Table.Row
                                key={index}
                                style={{
                                    backgroundColor: item.status === 'paid' ? '#dcffdb' : '#f5a9b1',
                                }}
                            >
                                <Table.Cell>{item.issue_date}</Table.Cell>
                                <Table.Cell>{item.due_date}</Table.Cell>
                                <Table.Cell>{formatter.format(item.amount)}</Table.Cell>
                                <Table.Cell>
                                    {item.status}
                                </Table.Cell>
                                <Table.Cell>
                                    <Button
                                        icon="credit card"
                                        content="Pay"
                                        primary
                                        disabled={item.status === 'paid' || currentBusiness?.store_id === undefined || currentBusiness === undefined}
                                        onClick={() => onCardPayment(item.invoice_id)}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
        </Segment>
    );
};

export default function BillingSubPage() {
    return (
        <Segment
            fluid
            basic
        >
            <CurrentPlanDisplay />
            <SubscriptionsHistory />
        </Segment>
    );
}